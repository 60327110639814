/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import QrReader from 'react-qr-reader';
import OtpInput from 'react-otp-input';
import {Button} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export interface QrProps {
  onComplete: (data: string) => void;
  onPinComplete: (data: string) => void;
  setStage: (stage: string) => void;
  captureEnabled: boolean;
  loading: boolean;
}
export const QrScanner = ({
  onComplete,
  onPinComplete,
  setStage,
  captureEnabled,
  loading,
}: QrProps) => {
  const [scanned, setScanned] = useState(false);
  const [isShowPin, setIsShowPin] = useState(false);
  const [staffPin, setStaffPin] = useState('');
  const handleScan = (data: any) => {
    if (data) {
      setScanned(true);
      onComplete(data);
    }
  };

  const handleError = (err: any) => {
    console.log(err);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!scanned && captureEnabled) {
        setStage('capture');
      }
    }, 40000);

    return () => clearTimeout(timer);
  }, [scanned, setStage, captureEnabled]);

  const pinButtonHandler = () => {
    console.log('staffPin', staffPin);
    onPinComplete(staffPin);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: isShowPin ? 'auto' : '90vh',
        width: '100vw',
        overflow: 'hidden',
        // padding: '1vh',
      }}
    >
      <Typography
        variant="h3"
        component="h2"
        fontWeight="fontWeightBold"
        textAlign="center"
        color="#3E82BF"
        marginY="25px"
      >
        <span
          onClick={() => setIsShowPin(false)}
          style={{textDecoration: 'underline', cursor: 'pointer'}}
        >
          Scan Your QR Code
        </span>{' '}
        Or{' '}
        <span
          onClick={() => setIsShowPin(true)}
          style={{textDecoration: 'underline', cursor: 'pointer'}}
        >
          Enter Pin
        </span>
      </Typography>
      {!isShowPin && (
        <Box
          sx={{
            width: '100vw',
            height: {sm: '200px', md: '300px', lg: '400px'},
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <QrReader
            delay={2000}
            onError={handleError}
            onScan={handleScan}
            facingMode="user"
            style={{width: '50%'}}
          />
        </Box>
      )}
      {isShowPin && (
        <Box sx={{marginTop: 6}}>
          <OtpInput
            value={staffPin}
            onChange={setStaffPin}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={props => <input {...props} />}
            shouldAutoFocus
            inputStyle={{
              width: '100px',
              height: '3.5rem',
              // margin: '0 1rem',
              border: '1px solid rgba(0, 0, 0, 0.3)',
              fontSize: '2rem',
              borderRadius: '4px',
              color: '#3E82BF',
            }}
          />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 4,
            }}
          >
            <Button
              fullWidth={true}
              variant="contained"
              size="medium"
              sx={{
                borderRadius: '4px',
                background: '#3E82BF',
                color: 'white',
              }}
              onClick={pinButtonHandler}
              startIcon={
                loading ? (
                  <CircularProgress size={23} sx={{color: '#FFFFFF'}} />
                ) : null
              }
              disabled={staffPin.length === 6 ? false : true}
            >
              {loading ? 'Loading...' : 'Submit'}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
