/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box} from '@mui/material';
import {AuthViewHeader} from '../../common/AuthViewHeader/AuthViewHeader';
import {CapturePhoto} from '../../common/ClockStaff/CapturePhoto';
import {QrScanner} from '../../common/ClockStaff/QrScanner';
import {Result} from '../../common/ClockStaff/Result';
import {useCallback, useEffect, useState} from 'react';
import {fileUpload} from '@digistaff/core';
import {app, setTenantKey} from '../../providers/DSApp';
import {setPinBasedClockInOut, setShiftClockInOut} from '@digistaff/shift';
import {useSearchParams} from 'react-router-dom';
import {getMyKioskSetting} from '@digistaff/business';

export const MainView = () => {
  const [stage, setStage] = useState('');
  const [imgUrl, setImgUrl] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [captureEnabled, setCaputreEnabled] = useState(true);

  const listKiosk = useCallback(async () => {
    return new Promise((resolve, reject) => {
      getMyKioskSetting(app)
        .then(res => resolve(res.data.getMyKioskSetting))
        .catch(err => reject(err));
    });
  }, []);

  const handleCaptureComplete = async (data: string) => {
    if (data === null) location.reload();

    const base64Data = data.substring(data.indexOf(',') + 1);
    const contentType = 'image/jpeg';

    fileUpload(app, base64Data, contentType, 'clock_snapshot.jpeg', 'base64')
      .then((res: any) => {
        setImgUrl(res.data.result.Location);
        setStage('qrcode');
      })
      .catch(err => console.log(err));
  };

  const handleQrComplete = async (qrCode: string) => {
    try {
      const result = await setShiftClockInOut(
        app,
        qrCode,
        imgUrl ? imgUrl : ''
      );
      setStatus(result);
      setStage('result');
    } catch (err: any) {
      setStatus(err.response.data);
      setStage('result');
    }
  };

  const handlePinComplete = async (pinCode: string) => {
    setLoading(true);
    try {
      const result = await setPinBasedClockInOut(
        app,
        pinCode,
        imgUrl ? imgUrl : ''
      );

      setStatus(result);
      setStage('result');
      setLoading(false);
    } catch (err: any) {
      setStatus(err.response.data);
      setStage('result');
      setLoading(false);
    }
  };

  const handleResultComplete = () => {
    setImgUrl(null);
    setStatus(null);

    if (captureEnabled) setStage('capture');
    else setStage('qrcode');
  };

  useEffect(() => {
    const tenant_key = localStorage.getItem('tenant_key');
    if (tenant_key !== null) setTenantKey(app, tenant_key);
    else {
      window.location.href = `${window.location.origin}/auth`;
    }

    (async () => {
      const settings: any = await listKiosk();
      if (settings.is_enable_photo_capture) {
        setCaputreEnabled(true);
        setStage('capture');
      } else {
        setCaputreEnabled(false);
        setStage('qrcode');
      }
    })();
  }, [searchParams, listKiosk]);

  return (
    <Box>
      <AuthViewHeader />

      {stage === 'capture' && (
        <CapturePhoto onComplete={handleCaptureComplete} />
      )}
      {stage === 'qrcode' && (
        <QrScanner
          onComplete={handleQrComplete}
          onPinComplete={handlePinComplete}
          setStage={setStage}
          captureEnabled={captureEnabled}
          loading={loading}
        />
      )}
      {stage === 'result' && (
        <Result status={status} onComplete={handleResultComplete} />
      )}
    </Box>
  );
};
